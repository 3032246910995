
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['authAllMap']),
    sendRepairDetailPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair:detail'];
    },
    sendRepairSignForPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair:sendOutSign'];
    },
    sendRepairSendbackPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair:sendBack'];
    },
    sendRepairPushPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair:push'];
    },
    spareDetailPermission () {
      return true || this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair_spare:detail'];
    },
    spareSignForPermission () {
      return true || this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair_spare:sendBackSign'];
    },
    spareClosePermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair_spare:close'];
    },
    sparePushPermission () {
      return this.$store.state.Auth && this.$store.state.Auth.authAllMap['gongxutong:lvyue:send_repair_spare:push'];
    },
  },
};
